import request from '@/utils/request'


// 查询新闻回复列表
export function listReply(query) {
  return request({
    url: '/news/newsReply/list',
    method: 'get',
    params: query
  })
}

// 查询新闻回复分页
export function pageReply(query) {
  return request({
    url: '/news/newsReply/page',
    method: 'get',
    params: query
  })
}

// 查询新闻回复详细
export function getReply(data) {
  return request({
    url: '/news/newsReply/detail',
    method: 'get',
    params: data
  })
}

// 新增新闻回复
export function addReply(data) {
  return request({
    url: '/news/newsReply/add',
    method: 'post',
    data: data
  })
}

// 修改新闻回复
export function updateReply(data) {
  return request({
    url: '/news/newsReply/edit',
    method: 'post',
    data: data
  })
}

// 删除新闻回复
export function delReply(data) {
  return request({
    url: '/news/newsReply/delete',
    method: 'post',
    data: data
  })
}
